// CustomNotification.js
import React, { useEffect } from 'react';
import './CustomNotification.css';

const CustomNotification = ({ message, type, onClose }) => {
    useEffect(() => {
        const timer = setTimeout(onClose, 7000);
        return () => clearTimeout(timer);
    }, [onClose]);

    return (
        <div className={`custom-notification ${type}`}>
            {message}
        </div>
    );
};

export default CustomNotification;
