import React, { useState } from 'react';
import Sidebar from '../newcomponents/Sidebar';
import Stepper from '../newcomponents/Stepper';
import PatientForm from '../newcomponents/PatientForm';
import CaptureStep from '../newcomponents/CaptureStep';
import './UserLandingPage.css';
import { toast } from 'react-toastify';
import logo from '../assets/dark-logo.png';
import thank from '../assets/thank.png';



export default function UserLandingPage() {
    const [currentStep, setCurrentStep] = useState(0);
    const [patientId, setPatientId] = useState('');

    const handleFormSubmit = async (formData) => {
        try {
            const response = await fetch('/api/submit_patient_data', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                },
                body: createFormData(formData),
            });

            let result;
            const contentType = response.headers.get("content-type");
            if (contentType && contentType.indexOf("application/json") !== -1) {
                result = await response.json();
            } else {
                result = await response.text();
                console.error('Non-JSON response:', result);
                throw new Error('Non-JSON response from server');
            }

            if (response.ok) {
                toast.success("Patient data submitted successfully!");
                setCurrentStep(1);
            } else {
                const errorMessage = result.detail || result.error || 'Failed to submit. Please try again.';
                console.error('Server error:', errorMessage);
                toast.error(errorMessage);
            }
        } catch (error) {
            console.error('Error during form submission:', error);
            toast.error(error.message || "An error occurred. Please try again.");
        }
    };

    const createFormData = (data) => {
        const formData = new FormData();
        const sessionId = `session_${data.userId}_${Date.now()}`;        

        formData.append('session_id', sessionId);
        formData.append('user_id', data.userId);
        formData.append('age', parseInt(data.age, 10));
        formData.append('birth_month', parseInt(data.birthMonth, 10));
        formData.append('birth_year', parseInt(data.birthYear, 10));
        formData.append('braces_reason', data.bracesReason || '');
        formData.append('medical_info', data.medicalInfo || '');
        formData.append('nail_biting', (data.habits?.nailBiting || false).toString());
        formData.append('teeth_grinding', (data.habits?.teethGrinding || false).toString());
        formData.append('thumb_sucking', (data.habits?.thumbSucking || false).toString());
        formData.append('dummy_use', (data.habits?.dummyUse || false).toString());
        formData.append('consent', (data.consent || false).toString());
        setPatientId(sessionId);
        return formData;
    };



    
    // Handle image verification response
    const handleVerificationResponse = (isVerified) => {
        if (isVerified) {
            toast.success(`Image for Step ${currentStep} verified successfully!`);
            if (currentStep < 6) {
                setCurrentStep(currentStep + 1);
            } else {
                setCurrentStep(7); // Move to the "Thank you" step
            }
        } else {
            toast.error(`Image verification failed for Step ${currentStep}. Please try again.`);
        }
    };

    return (
        <div className="user-landing-page">
            <Sidebar />
            <div className="logo-container" id='responsive-logo'>
                <img src={logo} alt="Orthopic Logo" className="logo" />
            </div>
            <div className="content-container">
                <Stepper currentStep={currentStep} />
                {currentStep === 0 && (
                    <h1 className="form-title">Let's get started</h1>
                )}

                {currentStep >= 1 && currentStep <= 6 && (
                    <h1 className="form-title">Dental Photography Guide</h1>
                )}

                {/* Step 0: Patient Form */}
                {currentStep === 0 && <PatientForm onSubmit={handleFormSubmit} />}

                {/* Steps 1 to 6: Image Capture Steps */}
                {currentStep >= 1 && currentStep <= 6 && (
                    <CaptureStep
                        step={currentStep}
                        patientId={patientId}
                        onVerificationResponse={handleVerificationResponse}
                    />
                )}

                {/* Step 7: Thank you message */}
                {currentStep === 7 && (
                    <div className="completion-message">
                        <span>Submitted</span>
                        <h2>Thank You!</h2>
                        <p>Your application is submitted, we will let you know.</p>
                        <img src={thank} alt={thank} className="completion-image" />
                        <button className="home-button" onClick={() => setCurrentStep(0)}>Back to Home</button>
                    </div>
                )}
            </div>
        </div>
    );
}
