import React, { useState } from 'react';
import Select from 'react-select';
import './PatientForm.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const PatientForm = ({ onSubmit }) => {
    const [formData, setFormData] = useState({
        userId: '', // User-provided numeric ID
        age: '',
        birthMonth: '',
        birthYear: '',
        bracesReason: '',
        medicalInfo: '',
        habits: {
            nailBiting: false,
            teethGrinding: false,
            thumbSucking: false,
            dummyUse: false,
        },
        consent: false,
    });

    const [isSubmitting, setIsSubmitting] = useState(false);

    // Months for dropdown
    const monthOptions = [
        { value: 1, label: 'January' },
        { value: 2, label: 'February' },
        { value: 3, label: 'March' },
        { value: 4, label: 'April' },
        { value: 5, label: 'May' },
        { value: 6, label: 'June' },
        { value: 7, label: 'July' },
        { value: 8, label: 'August' },
        { value: 9, label: 'September' },
        { value: 10, label: 'October' },
        { value: 11, label: 'November' },
        { value: 12, label: 'December' },
    ];

    // Dynamically generate year options based on age range
    const currentYear = new Date().getFullYear();
    const yearOptions = Array.from({ length: 100 }, (_, i) => ({
        value: currentYear - i,
        label: (currentYear - i).toString(),
    }));

    // Handle dropdown changes
    const handleMonthChange = (selectedOption) => {
        setFormData({ ...formData, birthMonth: selectedOption.value });
    };

    const handleYearChange = (selectedOption) => {
        setFormData({ ...formData, birthYear: selectedOption.value });
    };

    // Automatically calculate birth year based on age
    const handleAgeChange = (e) => {
        const age = e.target.value;
        const calculatedYear = currentYear - age;
        setFormData({
            ...formData,
            age,
            birthYear: age >= 5 && age <= 100 ? calculatedYear : '',
        });
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate inputs
        if (formData.age < 5 || formData.age > 100) {
            toast.error("Age must be between 5 and 100.", { theme: "colored" });
            return;
        }
        if (!formData.birthMonth) {
            toast.error("Please select a valid birth month.", { theme: "colored" });
            return;
        }
        if (!formData.birthYear) {
            toast.error("Please select a valid birth year.", { theme: "colored" });
            return;
        }
        if (!formData.consent) {
            toast.error("You must agree to the consent before proceeding!", { theme: "colored" });
            return;
        }

        setIsSubmitting(true); // Show loading spinner

        // Submit the form data
        await onSubmit(formData);
        setIsSubmitting(false); // Hide loading spinner
    };

    return (
        <form onSubmit={handleSubmit} className="patient-form">
            <ToastContainer />
            <div className="form-row">
                <div className="form-group">
                    <label>User ID</label>
                    <input
                        type="text"
                        id="userId"
                        value={formData.userId}
                        onChange={(e) => setFormData({ ...formData, userId: e.target.value })}
                        placeholder="Enter a numeric User ID"
                        required
                    />
                </div>
                <div className="form-group">
                    <label>Age</label>
                    <input
                        type="number"
                        id="age"
                        value={formData.age}
                        onChange={handleAgeChange}
                        required
                    />
                </div>
            </div>

            <div className="form-row">
                <div className="form-group">
                    <label>Birth Month</label>
                    <Select
                        options={monthOptions}
                        onChange={handleMonthChange}
                        placeholder="Select Month"
                        isClearable
                        required
                    />
                </div>
                <div className="form-group">
                    <label>Birth Year</label>
                    <Select
                        options={yearOptions}
                        onChange={handleYearChange}
                        value={
                            formData.birthYear
                                ? { value: formData.birthYear, label: formData.birthYear.toString() }
                                : null
                        }
                        placeholder="Select Year"
                        isClearable
                        required
                    />
                </div>
            </div>

            <div className="form-group">
                <label>Why do you want braces? <span className="required-label">*required</span></label>
                <textarea
                    id="bracesReason"
                    value={formData.bracesReason}
                    onChange={(e) => setFormData({ ...formData, bracesReason: e.target.value })}
                    required
                ></textarea>
            </div>

            <div className="form-group">
                <label>Do you have/had any of these habits?</label>
                <div className="checkbox-group">
                    <label>
                        <input
                            type="checkbox"
                            id="nailBiting"
                            checked={formData.habits.nailBiting}
                            onChange={(e) =>
                                setFormData({
                                    ...formData,
                                    habits: { ...formData.habits, nailBiting: e.target.checked },
                                })
                            }
                        />
                        Nail Biting
                    </label>
                    <label>
                        <input
                            type="checkbox"
                            id="teethGrinding"
                            checked={formData.habits.teethGrinding}
                            onChange={(e) =>
                                setFormData({
                                    ...formData,
                                    habits: { ...formData.habits, teethGrinding: e.target.checked },
                                })
                            }
                        />
                        Teeth Grinding
                    </label>
                    <label>
                        <input
                            type="checkbox"
                            id="thumbSucking"
                            checked={formData.habits.thumbSucking}
                            onChange={(e) =>
                                setFormData({
                                    ...formData,
                                    habits: { ...formData.habits, thumbSucking: e.target.checked },
                                })
                            }
                        />
                        Thumb Sucking
                    </label>
                    <label>
                        <input
                            type="checkbox"
                            id="dummyUse"
                            checked={formData.habits.dummyUse}
                            onChange={(e) =>
                                setFormData({
                                    ...formData,
                                    habits: { ...formData.habits, dummyUse: e.target.checked },
                                })
                            }
                        />
                        Dummy Use
                    </label>
                </div>
            </div>

            <div className="form-group">
                <label>Any medical information we should know?</label>
                <textarea
                    id="medicalInfo"
                    value={formData.medicalInfo}
                    onChange={(e) => setFormData({ ...formData, medicalInfo: e.target.value })}
                ></textarea>
            </div>

            <div className="form-group" id="consent">
                <input
                    type="checkbox"
                    id="consent"
                    checked={formData.consent}
                    onChange={(e) => setFormData({ ...formData, consent: e.target.checked })}
                />
                <label htmlFor="consent">
                    By clicking "Next," you consent to the use of your images solely for these purposes.
                </label>
            </div>

            <button
                type="submit"
                className="submit-button"
                disabled={isSubmitting}
            >
                {isSubmitting ? 'Submitting...' : 'Next'}
            </button>
        </form>
    );
};

export default PatientForm;
