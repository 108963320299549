import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Modal from 'react-modal';
import { FiDownload } from 'react-icons/fi';

Modal.setAppElement('#root');

function UserDetail() {
    const { userId } = useParams();
    const [user, setUser] = useState(null);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');

    useEffect(() => {
        fetch(`http://34.69.179.222:8080/api/get-user/${userId}`)
            .then(response => response.json())
            .then(data => {
                if (data.images) {
                    data.images = Object.values(data.images);
                }
                setUser(data);
            })
            .catch(error => console.error('Failed to fetch user details', error));
    }, [userId]);

    function openModal(imgUrl) {
        setSelectedImage(imgUrl);
        setModalIsOpen(true);
    }

    function closeModal() {
        setModalIsOpen(false);
    }

    // Updated function to handle image download
    const handleDownload = (url, fileName) => {
        fetch(`/api/download-image/?image_url=${encodeURIComponent(url)}`)
          .then(response => response.blob())
          .then(blob => {
            const downloadUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          })
          .catch(error => console.error('Error downloading the image', error));
      };
      
    
    
    

    if (!user) {
        return <div>Loading...</div>;
    }

    return (
        <div className="body-container">
            <h1>User Details</h1>
            <p><strong>Name:</strong> {user.name}</p>
            <p><strong>Date of Birth:</strong> {user.dob}</p>
            <p><strong>Braces Reason:</strong> {user.braces_reason}</p>
            <p><strong>Medical Info:</strong> {user.medical_info}</p>
            <p><strong>Additional Details:</strong> {user.additional_details}</p>
            <p><strong>Habits:</strong></p>
            <ul>
                <li>Nail Biting: {user.habits.nail_biting ? 'Yes' : 'No'}</li>
                <li>Teeth Grinding: {user.habits.teeth_grinding ? 'Yes' : 'No'}</li>
                <li>Thumb Sucking: {user.habits.thumb_sucking ? 'Yes' : 'No'}</li>
                <li>Dummy Use: {user.habits.dummy_use ? 'Yes' : 'No'}</li>
            </ul>
            <div>
                <h2>Images:</h2>
                {user.images && user.images.map((image, index) => (
                    <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                        <img src={image} alt={`User Image ${index}`} style={{ cursor: 'pointer', width: '100px', height: 'auto' }} onClick={() => openModal(image)} />
                        <button
                            onClick={() => handleDownload(image, `UserImage${index}.jpg`)}
                            style={{ marginLeft: '10px', display: 'flex', alignItems: 'center', border: 'none', background: 'none', cursor: 'pointer' }}
                        >
                            <FiDownload size={20} />
                        </button>
                    </div>
                ))}
            </div>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Image Preview"
                className="modal-content"
            >
                <img src={selectedImage} alt="Selected" className="modal-image" />
                <button onClick={closeModal} className="close-button">Close</button>
            </Modal>
        </div>
    );
}

export default UserDetail;
