import React from 'react';
import './Sidebar.css';
import logo from '../assets/logo.png';

const Sidebar = () => {
    return (
        <div className="sidebar">
            {/* Enhanced Background and Blur Layers */}
            <div className="blur-layer top-blur" />
            <div className="blur-layer bottom-blur" />

            {/* Logo and Content */}
            <div className="logo-container">
                <img src={logo} alt="Orthopic Logo" className="logo" />
            </div>
            <div className="content">
                <h2 className="title">Orthodontic Image Triaging</h2>
                <p className="description">
                    Orthopic is your trusted AI-powered dental imaging tool, designed to enhance the accuracy of orthodontic assessments. Capture, analyze, and improve your dental health with ease.
                </p>
            </div>
        </div>
    );
};

export default Sidebar;
