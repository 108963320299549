import step1 from "../assets/sample/sample_step_1.jpeg"
import step2 from "../assets/sample/sample_step_1.jpeg"
import step3 from "../assets/sample/sample_step_1.jpeg"
import step4 from "../assets/sample/sample_step_1.jpeg"
import step5 from "../assets/sample/sample_step_1.jpeg"

const sampleImages = [
    {step1},
    {step2},
    {step3},
    {step4},
    {step5},
];
export default sampleImages;
