

import React, { useEffect, useRef } from 'react';
import './Stepper.css';
import { FaCheck } from 'react-icons/fa';

const stepLabels = [
    'Personal Info',    // Step 0
    'Front Biting',  // Step 1
    'Front Teeth Apart',  // Step 2
    'Right Side Biting',  // Step 3
    'Left Side Biting',  // Step 4
    'All Top teeth',  // Step 5
    'All Bottom teeth',  // Step 6
    'Thank You',        // Step 7
];

const Stepper = ({ currentStep }) => {
    const stepperRef = useRef(null);

    // Scroll to the current step on mobile screens
    useEffect(() => {
        if (window.innerWidth <= 768 && stepperRef.current) {
            const currentStepElement = stepperRef.current.querySelectorAll('.step-item')[currentStep];
            if (currentStepElement) {
                // Scroll the stepper container to show the current step
                currentStepElement.scrollIntoView({
                    behavior: 'smooth',
                    inline: 'center',
                });
            }
        }
    }, [currentStep]);

    return (
        <div className="stepper-container" ref={stepperRef}>
            {stepLabels.map((label, index) => {
                const isCompleted = index < currentStep;
                const isInProgress = index === currentStep;
                const isPending = index > currentStep;

                const statusText = isCompleted
                    ? 'Completed'
                    : isInProgress
                    ? 'In Progress'
                    : 'Pending';

                return (
                    <div
                        key={index}
                        className={`step-item ${isCompleted ? 'completed' : isInProgress ? 'current' : 'pending'}`}
                    >
                        <div className="step-icon">
                            {isCompleted ? (
                                <span className="checkmark"><FaCheck /></span>
                            ) : (
                                <span className="circle" />
                            )}
                        </div>
                        <div className="step-info">
                            <div className="step-label">STEP {index + 1}</div>
                            <div className="step-name">{label}</div>
                            <div className={`step-status ${statusText.toLowerCase()}`}><p>{statusText}</p></div>
                        </div>
                        {index < stepLabels.length - 1 && <div className="step-line" />}
                    </div>
                );
            })}
        </div>
    );
};

export default Stepper;

