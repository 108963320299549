import React, { useState } from 'react';
import sideImage from '../assets/side-image.png';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function ConsentForm({ onAccept }) {
    const [isChecked, setIsChecked] = useState(false);

    const handleNext = () => {
        if (!isChecked) {
            toast.error('Please check the box to consent before proceeding!');
        } else {
            onAccept();
        }
    };

    return (
        <div className="consent-container" id='consent-container'>
            <ToastContainer />
            <div id="top-container-consent">
                <div className="content">
                    <h2>Start Your Orthodontic Journey Today!</h2>
                    <p>
                        Capture six essential photographs of your teeth to kickstart your orthodontic journey. For the best results, we recommend using a mobile phone or tablet. Having a friend or family member assist you can make the process easier and more effective. Please do not forget to use the cheek retractor!

                    </p>
                </div>
                <div>
                    <img src={sideImage} alt="Logo" />
                </div>
            </div>
            <div className="bottom-container">
                <div>
                    <h2>Welcome to ORTHOPIC</h2>
                    <p>
                        Take six essential photographs to begin your orthodontic assessment using our innovative AI-powered app. These images will assist our orthodontic experts in providing a comprehensive initial evaluation while also contributing to the continuous improvement of our assessment technology.
                    </p>
                </div>
                <div>
                    <h2>Privacy Commitment</h2>
                    <p>
                        We prioritise your privacy. Your images will be:
                    </p>
                    <ol>
                        <li>Used solely for orthodontic assessment and training to improve assessment accuracy.</li>
                        <li>Kept strictly confidential</li>
                        <li>Never shared or used for any other purpose without your explicit consent.</li>

                    </ol>
                    <p>By clicking <strong>"Next,"</strong> you agree to our image usage terms and take the first step towards    understanding your orthodontic needs.</p>
                </div>
            </div>
            <div className="form-group" id="consent">
                <input
                    type="checkbox"
                    id="consent-checkbox"
                    checked={isChecked}
                    onChange={(e) => setIsChecked(e.target.checked)}
                />
                <label htmlFor="consent-checkbox">
                    By clicking "Next," you consent to the use of your images solely for these purposes.
                    We prioritise your privacy and will not use the images otherwise without your permission.
                </label>
            </div>
            <button className="consent-button" onClick={handleNext}>
                Next
            </button>
        </div>
    );
}
