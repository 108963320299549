const captureInstructions = [
    {
        title: "Capturing Front Biting Image",
        description: "Capture a picture of your front teeth while biting together.",
        instructions: [
            "View the sample picture by clicking on the button on the left to get an idea of how the picture should look.",
            "Insert the cheek retractor and ensure it is properly positioned.",
            "Bite your teeth together firmly.",
            "Use the overlay to guide your face positioning.",
            "Face the camera directly.",
            "Take a clear picture.",
            "If the tool rejects the picture, please view the sample picture and retake it accordingly."
        ]
    },
    {
        title: "Capturing Front Teeth Apart Image",
        description: "Capture a picture of your front teeth without biting.",
        instructions: [
            "View the sample picture by clicking on the button on the left to get an idea of how the picture should look.",
            "Keeping the cheek retractor in place, now keep your teeth apart without biting.",
            "Use the overlay to guide your face positioning.",
            "Without biting down, face the camera directly and take a clear picture.",
            "If the tool rejects the picture, please view the sample picture and retake it accordingly."
        ]
    },
    {
        title: "Capturing Right Side Biting Image",
        description: "Capture a picture of your right side teeth while biting together.",
        instructions: [
            "View the sample picture by clicking on the button on the left to get an idea of how the picture should look.",
            "Keep the cheek retractor in place.",
            "Bite down together.",
            "Turn your face to the left-hand side so the right side of your bite is visible.",
            "Use the overlay to guide your face positioning.",
            "Take a clear picture showing all your back teeth.",
            "If the tool rejects the picture, please view the sample picture and retake it accordingly."
        ]
    },
    {
        title: "Capturing Left Side Biting Image",
        description: "Capture a picture of your left side teeth while biting together.",
        instructions: [
            "View the sample picture by clicking on the button on the left to get an idea of how the picture should look.",
            "Keep the cheek retractor in place.",
            "Bite down together.",
            "Turn your face to the right-hand side so the left side of your bite is visible.",
            "Use the overlay to guide your face positioning.",
            "Take a clear picture showing all your back teeth.",
            "If the tool rejects the picture, please view the sample picture and retake it accordingly."
        ]
    },
    {
        title: "Capturing All Top Teeth Image",
        description: "Capture a picture of your top teeth.",
        instructions: [
            "View the sample picture by clicking on the button on the left to get an idea of how the picture should look.",
            "Remove the cheek retractor.",
            "Open your mouth wide and tilt your head up towards the ceiling.",
            "Place the phone camera directly under the top set of teeth.",
            "Take a clear picture showing all your top teeth.",
            "If the tool rejects the picture, please view the sample picture and retake it accordingly."
        ]
    },
    {
        title: "Capturing All Bottom Teeth Image",
        description: "Capture a picture of your bottom teeth.",
        instructions: [
            "View the sample picture by clicking on the button on the left to get an idea of how the picture should look.",
            "Remove the cheek retractor.",
            "Open your mouth wide and tilt your head down towards the floor.",
            "Curl your tongue away to show all your bottom teeth.",
            "Place the phone camera directly over the bottom set of teeth.",
            "Take a clear picture showing all your bottom teeth.",
            "If the tool rejects the picture, please view the sample picture and retake it accordingly."
        ]
    },
];

export default captureInstructions;
