// import React, { useRef, useEffect, useState } from 'react';
// import { Modal, Button, Spinner } from 'react-bootstrap';
// import captureInstructions from './captureInstructions';
// import { toast } from 'react-toastify';
// import CustomNotification from './CustomNotification';
// import { FaSyncAlt, FaEye, FaCamera, FaPlus, FaMinus } from 'react-icons/fa';
// import step1 from "../assets/sample/sample_step_1.jpeg";
// import step2 from "../assets/sample/sample_step_2.jpeg";
// import step3 from "../assets/sample/sample_step_3.jpeg";
// import step4 from "../assets/sample/sample_step_4.jpeg";
// import step5 from "../assets/sample/sample_step_5.jpeg";
// import step6 from "../assets/sample/sample_step_6.jpeg";
// import cameraSwitchIcon from "../assets/cameraswitch.png";
// import './CaptureStep.css';

// const CaptureStep = ({ step, patientId, onVerificationResponse }) => {
//     const sampleImages = [step1, step2, step3, step4, step5, step6];
//     const sampleImage = sampleImages[step - 1];
//     const [notification, setNotification] = useState(null);
//     const videoRef = useRef(null);
//     const [showModal, setShowModal] = useState(false);
//     const [loading, setLoading] = useState(false);
//     const [zoomLevel, setZoomLevel] = useState(1);
//     const [facingMode, setFacingMode] = useState("environment");
//     const maxZoomLevel = 3;
//     const currentStepInstructions = captureInstructions[step - 1];


//     const showErrorNotification = (message) => {
//         setNotification({ message, type: 'error' });
//     };

//     // Start video stream
//     const startVideo = async () => {
//         const constraints = {
//             video: { facingMode },
//         };

//         try {
//             const stream = await navigator.mediaDevices.getUserMedia(constraints);
//             videoRef.current.srcObject = stream;
//         } catch (error) {
//             showErrorNotification("Could not access the camera.");
//         }
//     };

//     useEffect(() => {
//         startVideo();

//         return () => {
//             if (videoRef.current && videoRef.current.srcObject) {
//                 videoRef.current.srcObject.getTracks().forEach((track) => track.stop());
//             }
//         };
//     }, [facingMode]);

//     const handleToggleCamera = () => {
//         setFacingMode((prev) => (prev === "user" ? "environment" : "user"));
//     };

//     const handleZoomIn = () => setZoomLevel((prev) => Math.min(prev + 0.2, maxZoomLevel));
//     const handleZoomOut = () => setZoomLevel((prev) => Math.max(prev - 0.2, 1));

    
//     const handleCapture = async () => {
//         const canvas = document.createElement('canvas');
//         const ctx = canvas.getContext('2d');
//         canvas.width = videoRef.current.videoWidth;
//         canvas.height = videoRef.current.videoHeight;
//         ctx.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);

//         canvas.toBlob(async (blob) => {
//             const formData = new FormData();
//             formData.append('file', blob, 'image.jpg');
//             formData.append('patient_id', patientId);
//             formData.append('step', step);

//             setLoading(true);

//             try {
//                 const response = await fetch('/api/upload_and_verify_image', {
//                     method: 'POST',
//                     body: formData,
//                 });

//                 if (response.ok) {
//                     const result = await response.json();
//                     setNotification({ message: "Image uploaded and verified successfully!", type: 'success' });
//                     onVerificationResponse(true);
//                 } else {
//                     let errorMessage = "Verification failed. Please capture the image again.";
//                     let reason = "";
//                     try {
//                         const errorData = await response.json();
//                         reason = errorData.reason || "";
//                         errorMessage = errorData.message || errorMessage;
//                     } catch (parseError) {
//                         console.error("Error parsing response:", parseError);
//                     }
//                     showErrorNotification(`${errorMessage}${reason ? ` Reason: ${reason}` : ""}`);
//                     onVerificationResponse(false);
//                 }
//             } catch (error) {
//                 showErrorNotification("Failed to upload image. Please capture the image again.");
//                 onVerificationResponse(false);
//             } finally {
//                 setLoading(false);
//             }
//         }, 'image/jpeg');
//     };


//     // Array of overlay styles for each step
//     const overlayStyles = [
//         { borderRadius: "50% / 30%", height: "55%", width: "75%",left: '10%', top: '15%' },          // Step 1: Wider oval shape
//         { borderRadius: "30% / 20%", height: "50%", width: "60%",left: '20%', top: '18%' },          // Step 4: Ellipse shape
//         { borderRadius: "60% 40% 30% 50% / 40% 60% 50% 30%", height: "70%", width: "70%",left: '17%', top: '8%' },    // Step 5: Semi-circle (top teeth)
//         { borderRadius: "40% 60% 50% 30% / 60% 40% 30% 50%", height: "70%", width: "70%",left: '17%', top: '8%' },     // Step 6: Inverted semi-circle (bottom teeth)
//         { borderRadius: "50% 50% 0 0 / 100% 100% 0 0", height: "80%", width: "60%",left: '20%', top: '5%' },               // Step 2: Rectangle with rounded corners
//         { borderRadius: "30% 30% 100% 100%", height: "80%", width: "60%", left: '20%', top: '5%' }   // Step 2: Oval shape
//     ];


//     return (
//         <div className="capture-step-container">
//             {notification && (
//                 <CustomNotification
//                     message={notification.message}
//                     type={notification.type}
//                     onClose={() => setNotification(null)}
//                 />
//             )}
//             <div className="video-section">
//                 <div className="video-header">
//                     <img src={cameraSwitchIcon} className="toggle-camera-icon" style={{maxWidth: '30px'}} onClick={handleToggleCamera} />
//                     <span>Camera</span>
//                     <div className="zoom-controls">
//                         <Button variant="outline-light" onClick={handleZoomIn}>
//                             <FaPlus />
//                         </Button>
//                         <Button variant="outline-light" onClick={handleZoomOut}>
//                             <FaMinus />
//                         </Button>
//                     </div>
//                 </div>

//                 <div className="video-container">
//                     <video
//                         ref={videoRef}
//                         autoPlay
//                         muted
//                         playsInline
//                         className="video-feed"
//                         style={{ transform: `scale(${zoomLevel})`, maxHeight: '60vh' }}
//                     />
//                     <div
//                         className="overlay-guide"
//                         style={{
//                             position: 'absolute',
//                             ...overlayStyles[step - 1],
//                             border: '2px dashed #5E17EB',
//                             backgroundColor: 'rgba(94, 23, 235, 0.2)',
//                         }}
//                     />
//                 </div>

//                 <div className="button-group">
//                     <Button className='view-btn' variant="primary" onClick={() => setShowModal(true)}>
//                         <FaEye /> View Sample
//                     </Button>
//                     <Button variant="primary" onClick={handleCapture} disabled={loading}>
//                         {loading ? <Spinner animation="border" /> : <FaCamera />} Capture
//                     </Button>
//                 </div>
//             </div>

//             <div className="instructions-section">
//                 <h3>{currentStepInstructions.title}</h3>
//                 <p>{currentStepInstructions.description}</p>
//                 <ol>
//                     {currentStepInstructions.instructions.map((instruction, index) => (
//                         <li key={index}>{instruction}</li>
//                     ))}
//                 </ol>
//             </div>

//             <Modal show={showModal} onHide={() => setShowModal(false)} centered>
//                 <Modal.Header closeButton>
//                     <Modal.Title>Sample Image</Modal.Title>
//                 </Modal.Header>
//                 <Modal.Body>
//                     <img src={sampleImage} alt="Sample" className="modal-sample-image" />
//                 </Modal.Body>
//             </Modal>
//         </div>
//     );
// };

// export default CaptureStep;




import React, { useRef, useEffect, useState } from 'react';
import { Modal, Button, Spinner } from 'react-bootstrap';
import captureInstructions from './captureInstructions';
import { toast } from 'react-toastify';
import CustomNotification from './CustomNotification';
import { FaSyncAlt, FaEye, FaCamera, FaPlus, FaMinus } from 'react-icons/fa';
import step1 from "../assets/sample/sample_step_1.jpeg";
import step2 from "../assets/sample/sample_step_2.jpeg";
import step3 from "../assets/sample/sample_step_3.jpeg";
import step4 from "../assets/sample/sample_step_4.jpeg";
import step5 from "../assets/sample/sample_step_5.jpeg";
import step6 from "../assets/sample/sample_step_6.jpeg";
import cameraSwitchIcon from "../assets/cameraswitch.png";
import './CaptureStep.css';

const CaptureStep = ({ step, patientId, onVerificationResponse }) => {
    const sampleImages = [step1, step2, step3, step4, step5, step6];
    const sampleImage = sampleImages[step - 1];
    const [notification, setNotification] = useState(null);
    const [suggestedAction, setSuggestedAction] = useState(null); // New state for suggested action
    const videoRef = useRef(null);
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [zoomLevel, setZoomLevel] = useState(1);
    const [facingMode, setFacingMode] = useState("environment");
    const maxZoomLevel = 3;
    const currentStepInstructions = captureInstructions[step - 1];

    const showErrorNotification = (message) => {
        setNotification({ message, type: 'error' });
    };

    // Start video stream
    const startVideo = async () => {
        const constraints = {
            video: { facingMode },
        };

        try {
            const stream = await navigator.mediaDevices.getUserMedia(constraints);
            videoRef.current.srcObject = stream;
        } catch (error) {
            showErrorNotification("Could not access the camera.");
        }
    };

    useEffect(() => {
        startVideo();

        return () => {
            if (videoRef.current && videoRef.current.srcObject) {
                videoRef.current.srcObject.getTracks().forEach((track) => track.stop());
            }
        };
    }, [facingMode]);

    const handleToggleCamera = () => {
        setFacingMode((prev) => (prev === "user" ? "environment" : "user"));
    };

    const handleZoomIn = () => setZoomLevel((prev) => Math.min(prev + 0.2, maxZoomLevel));
    const handleZoomOut = () => setZoomLevel((prev) => Math.max(prev - 0.2, 1));

    const handleCapture = async () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = videoRef.current.videoWidth;
        canvas.height = videoRef.current.videoHeight;
        ctx.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);

        canvas.toBlob(async (blob) => {
            const formData = new FormData();
            formData.append('file', blob, 'image.jpg');
            formData.append('patient_id', patientId);
            formData.append('step', step);

            setLoading(true);

            try {
                const response = await fetch('/api/upload_and_verify_image', {
                    method: 'POST',
                    body: formData,
                });

                if (response.ok) {
                    const result = await response.json();
                    setNotification({ message: "Image uploaded and verified successfully!", type: 'success' });
                    setSuggestedAction(null); // Clear suggested action on success
                    onVerificationResponse(true);
                } else {
                    const errorData = await response.json();
                    const reason = errorData.reason || "Verification failed.";
                    const action = errorData.suggested_action || "Please try again.";
                    
                    // Update suggested action dynamically
                    setSuggestedAction(action);
                    
                    showErrorNotification(reason);
                    onVerificationResponse(false);
                }
            } catch (error) {
                showErrorNotification("Failed to upload image. Please capture the image again.");
                onVerificationResponse(false);
            } finally {
                setLoading(false);
            }
        }, 'image/jpeg');
    };

    const overlayStyles = [
        { borderRadius: "50% / 30%", height: "55%", width: "75%", left: '10%', top: '15%' },          
        { borderRadius: "30% / 20%", height: "50%", width: "60%", left: '20%', top: '18%' },         
        { borderRadius: "60% 40% 30% 50% / 40% 60% 50% 30%", height: "70%", width: "70%", left: '17%', top: '8%' },    
        { borderRadius: "40% 60% 50% 30% / 60% 40% 30% 50%", height: "70%", width: "70%", left: '17%', top: '8%' },     
        { borderRadius: "50% 50% 0 0 / 100% 100% 0 0", height: "80%", width: "60%", left: '20%', top: '5%' },              
        { borderRadius: "30% 30% 100% 100%", height: "80%", width: "60%", left: '20%', top: '5%' }   
    ];

    return (
        <div className="capture-step-container">
            {notification && (
                <CustomNotification
                    message={notification.message}
                    type={notification.type}
                    onClose={() => setNotification(null)}
                />
            )}
            {suggestedAction && (
                <div className="suggested-action" style={{ color: "darkpurple", fontWeight: "bold", fontStyle: "italic" }}>
                    {suggestedAction}
                </div>
            )}
            <div className="video-section">
                <div className="video-header">
                    <img src={cameraSwitchIcon} className="toggle-camera-icon" style={{ maxWidth: '30px' }} onClick={handleToggleCamera} />
                    <span>Camera</span>
                    <div className="zoom-controls">
                        <Button variant="outline-light" onClick={handleZoomIn}>
                            <FaPlus />
                        </Button>
                        <Button variant="outline-light" onClick={handleZoomOut}>
                            <FaMinus />
                        </Button>
                    </div>
                </div>

                <div className="video-container">
                    <video
                        ref={videoRef}
                        autoPlay
                        muted
                        playsInline
                        className="video-feed"
                        style={{ transform: `scale(${zoomLevel})`, maxHeight: '60vh' }}
                    />
                    <div
                        className="overlay-guide"
                        style={{
                            position: 'absolute',
                            ...overlayStyles[step - 1],
                            border: '2px dashed #5E17EB',
                            backgroundColor: 'rgba(94, 23, 235, 0.2)',
                        }}
                    />
                </div>

                <div className="button-group">
                    <Button className='view-btn' variant="primary" onClick={() => setShowModal(true)}>
                        <FaEye /> View Sample
                    </Button>
                    <Button variant="primary" onClick={handleCapture} disabled={loading}>
                        {loading ? <Spinner animation="border" /> : <FaCamera />} Capture
                    </Button>
                </div>
            </div>

            <div className="instructions-section">
                <h3>{currentStepInstructions.title}</h3>
                <p>{currentStepInstructions.description}</p>
                <ol>
                    {currentStepInstructions.instructions.map((instruction, index) => (
                        <li key={index}>{instruction}</li>
                    ))}
                </ol>
            </div>

            <Modal show={showModal} onHide={() => setShowModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Sample Image</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <img src={sampleImage} alt="Sample" className="modal-sample-image" />
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default CaptureStep;
