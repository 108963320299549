import React from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../newcomponents/Sidebar';
import ConsentForm from '../newcomponents/ConsentForm';
import './UserLandingPage.css';
import logo from '../assets/dark-logo.png';

export default function ConsentPage() {
    const navigate = useNavigate();

    // Handle consent acceptance
    const handleAcceptConsent = () => {
        navigate('/submit'); // Navigate to the /submit route
    };

    return (
        <div className="user-landing-page" id='consent-page-landing'>
            <Sidebar />
            <div className="logo-container" id='responsive-logo'>
                <img src={logo} alt="Orthopic Logo" className="logo" />
            </div>
            <div className="content-container" id='consent-page'>
                <ConsentForm onAccept={handleAcceptConsent} />
            </div>
        </div>
    );
}
